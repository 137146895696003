// src/components/PostPreviewList.js
import React from "react";
import { Grid, CircularProgress } from "@mui/material";
import PostPreview from "./PostPreview";

function PostPreviewList({ posts }) {
  if (!posts || posts.length === 0) {
    return <CircularProgress />; // Display a loading spinner
  }

  return (
    <Grid container spacing={3} mt={3} mb={4}>
      {posts.map((post) => (
        <Grid item xs={12} sm={6} md={4} key={post.postId}>
          <PostPreview post={post} />
        </Grid>
      ))}
    </Grid>    
  );
  }

export default PostPreviewList;

