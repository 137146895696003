// src/App.js
import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, 
  Route,
  Navigate, } from "react-router-dom";
import { getPosts } from "./apiService";
import { Box, CssBaseline  } from "@mui/material"; 
// Add Container back to the import

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Header from "./components/Header";
import Hero from "./components/Hero";
import PostPreviewList from "./components/PostPreviewList";
import Post from "./components/Post";
import UpdatePost from "./components/UpdatePost";
import DeletePost from "./components/DeletePost";
import CreatePost from "./components/CreatePost";
import AllPosts from "./components/AllPosts";

Amplify.configure(awsconfig);

function App() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts()
      .then((response) => {
        // Sort by createdAt in descending order and get the latest 3
        const latestPosts = response.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 3);

        setPosts(latestPosts);
      })
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  return (
    <Authenticator.Provider>
      <Router>
        <CssBaseline />
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <Header />
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Hero />
                    <PostPreviewList posts={posts} />
                  </>
                }
              />
              <Route path="/posts" element={<AllPosts posts={posts} />} />
              <Route path="/posts/:postId" element={<Post posts={posts} />} />
              <Route
                path="/posts/:postId/edit"
                element={
                  <Authenticator>
                    <UpdatePost posts={posts} />
                  </Authenticator>
                }
              />
              <Route
                path="/posts/:postId/delete"
                element={
                  <Authenticator>
                    <DeletePost />
                  </Authenticator>
                }
              />
              <Route
                path="/posts/new"
                element={
                  <Authenticator>
                    <CreatePost />
                  </Authenticator>
                }              
              />
              <Route
                path="/auth"
                element={
                  <Box className="signInModal">
                    <Authenticator /> {/* Render the Authenticator component directly */}
                  </Box>
                }
              /> 
              {/* Add this Navigate component */}
              <Route path="/auth/*" element={<Navigate to="/" replace />} />
            </Routes>          
        </Box>
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
