// src/components/UpdatePost.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Container, Box, Typography, Input, FormControl, FormLabel } from '@mui/material';
import { getPostById, updatePost } from '../apiService';
import { Avatar } from '@mui/material'; // For displaying the image

function UpdatePost() {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    getPostById(postId)
      .then(response => {
        setTitle(response.data.title);
        setContent(response.data.content);
        setSelectedImage(response.data.postImagesUrl.replaceAll('"', ''));
      })
      .catch(error => console.error('Error fetching post:', error));
  }, [postId]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    try {
      const response = await updatePost(postId, formData); 
      console.log('Post updated:', response.data);
      navigate(`/posts/${postId}`); // Redirect to the updated post
    } catch (error) {
      console.error('Error updating post:', error);
      // Add error handling/display
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Update Post
        </Typography>
        {selectedImage && ( // Display image if it's selected
          <Avatar 
            src={selectedImage} // Show the selected image (or existing image if not changed)
            variant="square"   // Make the avatar square to match the image aspect ratio
            sx={{ width: 200, height: 200, mb: 2 }}  // Adjust size and margin as needed
          />
        )}
      </Box>
      <form onSubmit={handleSubmit}>
      <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Content"
            variant="outlined"
            multiline
            rows={8} // Adjust the number of rows as needed
            value={content}
            onChange={(e) => setContent(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
          <FormLabel>Image</FormLabel>
          <Input type="file" accept="image/png" onChange={handleImageChange} />
          </FormControl>

          <Button variant="contained" type="submit">
            Create Post
          </Button>
      </form>
    </Container>
  );
}

export default UpdatePost;
