// src/components/AllPosts.js
import React, { useState, useEffect } from 'react';
import { getPosts } from '../apiService';
import PostPreviewList from './PostPreviewList';

function AllPosts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts()
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => console.error('Error fetching posts:', error));
  }, []); 

  return <PostPreviewList posts={posts} />;
}

export default AllPosts;
