// src/components/PostPreview.js
import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  CardMedia,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import dayjs from "dayjs";

function PostPreview({ post }) {
  // Find the end of the first paragraph
  const firstParagraphEndIndex = post.content.indexOf('\n\n'); // Find the first double newline
  const contentPreview = firstParagraphEndIndex !== -1
    ? post.content.slice(0, firstParagraphEndIndex) 
    : post.content.slice(0, 100); // If no double newline, take first 100 characters


  return (
    <Card>
      {/* Display the image if it exists */}
      {post.postImagesUrl && ( // Check if postImagesUrl is not null or empty
        <CardMedia
          component="img"
          height="140"
          image={post.postImagesUrl.replaceAll('"', '')} // Remove extra quotes
          alt="Post Image"
        />
      )}
      <CardContent>
        <Typography variant="h6" component="h2" gutterBottom>
          {post.title}
        </Typography>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {dayjs(post.createdAt).format("MMMM D, YYYY")}
        </Typography>
        <ReactMarkdown>{contentPreview}</ReactMarkdown>
      </CardContent>
      <CardActions>
        <Button
          component={RouterLink} // Use RouterLink here
          to={`/posts/${post.postId}`} // Make sure post.postId is correctly passed
          size="small"
        >
          Read More
        </Button>
      </CardActions>
    </Card>
  );
}

export default PostPreview;

