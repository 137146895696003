// This file will contain all the functions that will make API calls to the server.

import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;


export const getPosts = () => {
  return axios.get(`${BASE_URL}/getposts`);
};

export const getPostById = (postId) => {
  return axios.get(`${BASE_URL}/getposts/${postId}`);
};

export const createPost = (postData) => {
  return axios.post(`${BASE_URL}/createpost`, postData, { 
    headers: { 'Content-Type': 'multipart/form-data' } // Important for image uploads
  }); 
};

export const updatePost = (postId, postData) => {
  return axios.put(`${BASE_URL}/updatepost/${postId}`, postData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const deletePost = (postId) => {
  return axios.delete(`${BASE_URL}/deletepost/${postId}`);
};


// Add more functions for updatePost and deletePost 

