// src/components/DeletePost.js
import React, { useState } from 'react'; // Import useState
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { deletePost } from '../apiService';

function DeletePost() {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false); 

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleDelete = async () => {
    try {
      await deletePost(postId);
      console.log('Post deleted:', postId);
      navigate('/'); // Redirect to the home page
    } catch (error) {
      console.error('Error deleting post:', error);
      // Add error handling/display
    }
  };

  return (
    <>
      <Button color="error" onClick={handleOpenDialog}>
        Delete Post
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeletePost;
