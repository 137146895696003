import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Typography, Box, Container, Button } from "@mui/material";
import ReactMarkdown from "react-markdown";
import dayjs from "dayjs";
import NotFound from "./NotFound";
import { getPostById } from "../apiService";
import "../styles.css";
import { useAuthenticator } from "@aws-amplify/ui-react";



function Post({ posts }) {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPostById(postId)
      .then((response) => {
        setPost(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
        setPost(null); // Set post to null in case of an error
        setIsLoading(false);
      });
  }, [postId]);
  
  const { user } = useAuthenticator((context) => [context.user]); // Get the user object

  if (isLoading) {
    return <div>Loading...</div>; // Or display a loading spinner
  }

  if (!post) {
    return <NotFound />;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h3" component="h1">
          {post.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {dayjs(post.createdAt).format("MMMM D, YYYY")}
        </Typography>

        {/* Display the image if it exists */}
        {post.postImagesUrl && (
          <img
            src={post.postImagesUrl.replaceAll('"', "")} // Remove extra quotes
            alt={"Post Image for" + post.title} // Update alt text
            style={{ width: "100%", marginBottom: "1rem" }}
          />
        )}
      </Box>      
      {/* Render the post content using ReactMarkdown */}
      <ReactMarkdown>{post.content}</ReactMarkdown>
            {/* Show buttons only if the user is authenticated */}
                    {user && ( 
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button component={RouterLink} to={`/posts/${post.postId}/edit`} variant="outlined" color="primary" sx={{ mr: 2 }}>
                      Edit
                    </Button>
                    <Button component={RouterLink} to={`/posts/${post.postId}/delete`} variant="outlined" color="error">
                      Delete
                    </Button>
                  </Box>
                )}
               </Container>
            );
          }

export default Post;

