// src/components/Hero.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import '../styles.css';

function Hero() {
  return (
    <Box className="hero-container">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h4" component="h1" align="center" gutterBottom sx={{ mb: 2 }}>
          Hello, I'm Ashley
        </Typography>
        <Typography variant="body1" align="center" paragraph sx={{ mb: 3 }}>
          Welcome to my blog. I write about web development, technology, and sometimes random stuff.
        </Typography>
        <Button variant="contained" color="primary" href="#posts" sx={{ textTransform: 'none' }}>
          Read Latest Posts
        </Button>
      </motion.div>
    </Box>
  );
}

export default Hero;
