// src/components/Header.js
import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "../styles.css";

function Header() {
  const location = useLocation();
  const showBackButton = location.pathname !== "/";
  const { signOut, user } = useAuthenticator((context) => [
    context.signOut,
    context.user,
  ]);

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Logo and Title (Left Side) */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src="/images/header.png" alt="Header Logo" height="50" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 2 }}>
            MasterAsh!
          </Typography>
        </Box>

        {/* Buttons (Right Side) */}
        <Box sx={{ display: "flex", alignItems: "center" }}> 
          <Button component={RouterLink} to="/posts" color="inherit" className="header-button">
            All Posts
          </Button>

          {user ? ( 
            <>
              <Button component={RouterLink} to="/posts/new" color="inherit" className="header-button">
                Create Post
              </Button>
              <Button color="inherit" onClick={signOut}>
                Sign Out
              </Button>
            </>
          ) : (
            <Button component={RouterLink} to="/auth" color="inherit">
              Sign In
            </Button>
          )}

          {showBackButton && (
            <Button component={RouterLink} to="/" color="inherit">
              Back to Home
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

