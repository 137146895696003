// src/components/CreatePost.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Container, 
  Box, 
  Typography,
  Input,
  FormControl,
  FormLabel,
} from '@mui/material';
import { createPost } from '../apiService';

function CreatePost() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    try {
      const response = await createPost(formData); // Pass FormData to apiService
      console.log('Post created:', response.data);
      navigate('/');
    } catch (error) {
      console.error('Error creating post:', error);
      // Add error handling/display here (e.g., show error messages to the user)
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Create a New Post
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Content"
            variant="outlined"
            multiline
            rows={8} // Adjust the number of rows as needed
            value={content}
            onChange={(e) => setContent(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
          <FormLabel>Image</FormLabel>
          <Input type="file" accept="image/png" onChange={handleImageChange} />
          </FormControl>

          <Button variant="contained" type="submit">
            Create Post
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default CreatePost;
