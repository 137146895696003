// src/components/NotFound.js
import React from 'react';
import { Typography, Box, Container } from '@mui/material';

function NotFound() {
  return (
    <Container maxWidth="md">
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          404 - Page Not Found
        </Typography>
        <Typography variant="body1">
          Oops! The page you're looking for doesn't exist.
        </Typography>
      </Box>
    </Container>
  );
}

export default NotFound;
